<script>
import LogotypeImage from '@/components/LogotypeImage.vue';
import { addClass } from '@/helpers/text-editor-helper.js';


export default {
  components: { LogotypeImage },
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  data() {
    const leftLowResImage = this.block.fields.MediaContentLeft?.templateId === 'Image'
      && this.$toLitiumMediaUrl(this.block.fields.MediaContentLeft.id, { maxWidth: 50 });

    const rightLowResImage = this.block.fields.MediaContentRight?.templateId === 'Image'
      && this.$toLitiumMediaUrl(this.block.fields.MediaContentRight.id, { maxWidth: 50 });

    return {
      leftImageUrl: leftLowResImage,
      rightImageUrl: rightLowResImage,
    };
  },
  mounted() {
    if (this.leftImageUrl) {
      this.loadHighRes(
        this.block.fields.MediaContentLeft,
        this.$refs.imageContainerLeft,
        (highUrl) => {
          this.leftImageUrl = highUrl;
        },
      );
    }

    if (this.rightImageUrl) {
      this.loadHighRes(
        this.block.fields.MediaContentRight,
        this.$refs.imageContainerRight,
        (highUrl) => {
          this.rightImageUrl = highUrl;
        },
      );
    }
  },
  computed: {
    leftMedia() {
      return {
        item: this.block.fields.MediaContentLeft,
        isVideo: !this.leftImageUrl,
        isAutoplay: this.block.fields.ContentLeftAutoplay,
      };
    },
    rightMedia() {
      return {
        item: this.block.fields.MediaContentRight,
        isVideo: !this.rightImageUrl,
        isAutoplay: this.block.fields.ContentRightAutoplay,
      };
    },
    textContent() {
      return addClass(this.block.fields.TextEditor, 'text-with-two-images');
    },
    logotypes() {
      return this.block.fields.Logotypes;
    },
  },
  methods: {
    getVideoSource(video) {
      return this.$toLitiumMediaUrl(video.id);
    },
    loadHighRes(image, container, callback) {
      const mediaAspectRatio = image.width / image.height;
      const containerAspectRatio = container.clientWidth / container.clientHeight;

      const highResUrl = this.$toLitiumMediaUrl(image.id,
        {
          ...(mediaAspectRatio < containerAspectRatio
            ? { maxWidth: container.clientWidth }
            : { maxHeight: container.clientHeight }),
        });

      const img = new Image();
      img.onload = () => callback(highResUrl);
      img.src = highResUrl;
    },
  },
};
</script>

<template>
  <div class="text-with-two-images__wrapper">
    <div class="text-with-two-images">
      <div class="text-with-two-images__positioning-wrapper">
        <div
          :class="['text-with-two-images__right-media',
            {'text-with-two-images__right-media--single':!leftMedia.item}]"
          v-if="rightMedia.item"
          ref="imageContainerRight"
        >
        <div class="text-with-two-images__right-media-spacer"></div>
          <img
            class="text-with-two-images__right-image"
            v-if="!rightMedia.isVideo"
            :src="rightImageUrl"
          />
          <video
            class="text-with-two-images__right-video"
            v-if="rightMedia.isVideo"
            :autoplay="rightMedia.isAutoplay"
            :loop="rightMedia.isAutoplay"
            :muted="rightMedia.isAutoplay"
            :controls="!rightMedia.isAutoplay"
            playsinline
          >
            <source :src="getVideoSource(rightMedia.item)" />
          </video>
        </div>
        <div class="text-with-two-images__text-and-logos">
          <div class="text-with-two-images__text text-editor-content" v-html="textContent"></div>
          <div class="text-with-two-images__logotypes" v-if="logotypes">
            <logotype-image
            v-for="logo in logotypes"
            :key="logo"
            :logotype="logo"
          />
          </div>
        </div>
      </div>
      <div
        class="text-with-two-images__left-media"
        v-if="leftMedia.item"
        ref="imageContainerLeft"
      >
        <div class="text-with-two-images__left-media-spacer"></div>
        <img
          class="text-with-two-images__left-image"
          v-if="!leftMedia.isVideo"
          :src="leftImageUrl"
        />
        <video
          class="text-with-two-images__left-video"
          v-if="leftMedia.isVideo"
          :autoplay="leftMedia.isAutoplay"
          :loop="leftMedia.isAutoplay"
          :muted="leftMedia.isAutoplay"
          :controls="!leftMedia.isAutoplay"
          playsinline
        >
          <source :src="getVideoSource(leftMedia.item)" />
        </video>
      </div>
    </div>
  </div>
</template>

<style>
.text-with-two-images__wrapper {
  max-width: var(--site-max-width);
  margin: auto;
  display: flex;
  width: 100%;
}

.text-with-two-images {
  width: 100%;
  padding: 20px 10px;
  max-width: calc(var(--site-max-width) - 100px);
  margin-left: auto;
}

.text-with-two-images__positioning-wrapper {
  display: flex;
  flex-direction: column-reverse;
}

.text-with-two-images__text-and-logos {
  margin-bottom: 20px;
}

.text-with-two-images__text {
  width: 100%;
  padding-right: 15px;
  line-height: 24px;
  color: var(--color-black);
}

.text-with-two-images__logotypes {
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(2, 120px);
  grid-template-rows: auto;
  gap: 10px 20px;
  justify-content: center;
  margin: 20px 0 50px;
  max-width: 520px;
}

.text-with-two-images__left-media {
  max-width: 571px;
  margin-right: 111px;
  position: relative;
}

.text-with-two-images__left-media-spacer {
  padding-top: 101%;
}

.text-with-two-images__right-media {
  max-width: 698px;
  margin-left: 42px;
  margin-bottom: 38px;
  position: relative;
}

.text-with-two-images__right-media-spacer {
 padding-top: 133.573%;
}

.text-with-two-images__right-media--single {
  margin-bottom: 0;
}

.text-with-two-images__left-image,
.text-with-two-images__left-video,
.text-with-two-images__right-image,
.text-with-two-images__right-video {
  top: 0;
  height: 100%;
  position: absolute;
  width: 100%;
  outline: none;
  display: block;
  object-fit: cover;
  object-position: center;
}

h1.text-with-two-images__text-element,
h2.text-with-two-images__text-element,
h3.text-with-two-images__text-element,
h4.text-with-two-images__text-element {
  margin: 0 0 15px;
  text-align: left;
  line-height: 32px;
}

@media (--tabletAndDesktop) {
  .text-with-two-images {
    padding: 20px 0 20px 10px;
  }

  .text-with-two-images__positioning-wrapper {
    display: block;
    padding-top: 52px;
  }

  .text-with-two-images__text-and-logos {
    margin-top: 60px;
    margin-bottom: 90px;
    width: 40%;
    max-width: 520px;
  }

  .text-with-two-images__text {
    padding: 0;
  }

  .text-with-two-images__logotypes {
    margin: 30px 0;
    grid-template-columns: repeat(3, min( 120px, 30%));
    justify-content: flex-start;
  }

  .text-with-two-images__left-media {
    width: 40%;
    max-width: 520px;
  }

  .text-with-two-images__right-media {
    float: right;
    width: 55%;
    max-width: 700px;
    margin: 0;
  }
}
</style>
